import React from 'react';
import {
    
    MainItemBroadType,
} from '../../types/internal';
import {
	Assessment,
	Credential,
	LearningOpportunity,
	TransferValue,
	TransferIntermediary,
	Outline,
	CompetencyFramework,
	ConceptScheme,
	Pathway, 
	PathwayComponent,
	PathwaySet,
	Collection,
    ScheduledOffering,
    SupportService,
    Occupation,
	DataSetProfile,
	Job,
	Task,
	WorkRole,
    CredentialingAction,
    Rubric
} from '../../types/external';
import { isFilled } from '../../validation';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import orgIcon from '../../assets/images/icons/icon-organization-green-blue-01.svg';
import { Label, UnorderedList, ListWithoutBullet } from './styles';
import TextLink from '../TextLink';
import HasAnyFilled from '../HasAnyFilled';
import { detailSectionProperties, isTransferValue, isCredential } from '../../utils';
import TruncatedItemList from '../DetailPage/TruncatedItemList';
import styled from 'styled-components/macro';
import AJAXResourceList, { getEnglish, renderLink } from '../AJAXResourceList';
import { appURL } from '../../shared/globals';

interface Props {
	item: Assessment | LearningOpportunity | Credential | TransferValue | TransferIntermediary | CompetencyFramework | ConceptScheme | Occupation | Pathway | PathwayComponent | PathwaySet | Collection | DataSetProfile | ScheduledOffering | SupportService | Job | Task | WorkRole | CredentialingAction | Rubric;
	pageSectionDescription?: string;
}

export default function RelatedOrganizationPageSection(props: Props) {
	var looseItem = props.item as any;
    return (
        <HasAnyFilled
            data={props.item}
            keys={detailSectionProperties.relatedOrganizations}>
			<PageSection icon={orgIcon} title="Related Organization(s)" description={props.pageSectionDescription}>
				<OrganizationSection values={looseItem.OwnedBy?.Values} label="Owned By" innerKey="ownedby" />
				<OrganizationSection values={looseItem.OfferedBy?.Values} label="Offered By" innerKey="offeredby" />
				<OrganizationSection values={looseItem.OwnedOfferedBy?.Values} label="Owned and Offered By" innerKey="ownedofferedby" />
				<OrganizationSection values={looseItem.RenewedBy?.Values} label="Renewed By" innerKey="renewedby" />
				<OrganizationSection values={looseItem.RevokedBy?.Values} label="Revoked By" innerKey="revokedby" />
				<OrganizationSection values={looseItem.CopyrightHolder} label="Copyright Holder" innerKey="copyrightholder" />
				<OrganizationSection values={looseItem.Creator} label="Creator" innerKey="creator" />
				<OrganizationSection values={looseItem.Publisher} label="Publisher" innerKey="publisher" />
				<OrganizationSection values={looseItem.Creator?.Values} label="Creator" innerKey="creator" />
				<OrganizationSection values={looseItem.Publisher?.Values} label="Publisher" innerKey="publisher" />
				<OrganizationSection values={looseItem.RightsHolder} label="Rights Holder" innerKey="rightsholder" />
				<OrganizationSection values={looseItem.DataProviderMain} label="Data Provider" innerKey="dataprovider" />
				<OrganizationSection values={looseItem.AssertedBy?.Values} label="Asserted By" innerKey="assertedby" />
				<OrganizationSection values={looseItem.ActingAgent?.Values} label="Acting Agent" innerKey="actingagent" />
				<OrganizationSection values={looseItem.Participant?.Values} label="Participant" innerKey="participant" />
            </PageSection>
        </HasAnyFilled>
    );
}

function OrganizationSection(props: { label: string, values: Array<Outline>, innerKey: string }) {
	return props.values?.length > 0 && (
		<PageSectionItem>
			<Label>{props.label}</Label>
			<TruncatedItemList
				ListItemsComponent={UnorderedList}
				source={props.values}
				renderItemWith={(item, index) => {
					return (
						<ListWithoutBullet
							key={`cr-relatedorganization-` + props.innerKey + `-${index}`}>
							<TextLink outline={item} />
						</ListWithoutBullet>
					)
				}}
				truncateAfter={5}
			/>
		</PageSectionItem>
	) || null;
}

export function RelatedOrganizationPageSectionAJAXResourceList(props: { source: any, pageSectionDescription: string }) {
	console.log("Source", props.source);
	if (!props.source) {
		return null;
	}

	var allURIProperties = ["ceterms:ownedBy", "ceterms:offeredBy", "ceterms:renewedBy", "ceterms:revokedBy", "ceterms:copyrightHolder", "ceasn:creator", "ceasn:publisher", "ceasn:rightsHolder", "ceterms:assertedBy", "ceterms:actingAgent", "ceterma:participant"];
	var allURIs = allURIProperties.flatMap(property => props.source[property] || []);
	if (allURIs.length == 0) {
		return null;
	}

	return (
		<>
			<PageSection icon={orgIcon} title="Related Organization(s)" description={props.pageSectionDescription}>
				<OrganizationSectionAJAXResourceList label="Owned By" source={props.source} property="ceterms:ownedBy" />
				<OrganizationSectionAJAXResourceList label="Offered By" source={props.source} property="ceterms:offeredBy" />
				<OrganizationSectionAJAXResourceList label="Renewed By" source={props.source} property="ceterms:renewedBy" />
				<OrganizationSectionAJAXResourceList label="Revoked By" source={props.source} property="ceterms:revokedBy" />
				<OrganizationSectionAJAXResourceList label="Copyright Holder" source={props.source} property="ceterms:copyrightHolder" />
				<OrganizationSectionAJAXResourceList label="Creator" source={props.source} property="ceasn:creator" />
				<OrganizationSectionAJAXResourceList label="Publisher" source={props.source} property="ceasn:publisher" />
				<OrganizationSectionAJAXResourceList label="Rights Holder" source={props.source} property="ceasn:rightsHolder" />
				<OrganizationSectionAJAXResourceList label="Asserted By" source={props.source} property="ceterms:assertedBy" />
				<OrganizationSectionAJAXResourceList label="Acting Agent" source={props.source} property="ceterms:actingAgent" />
				<OrganizationSectionAJAXResourceList label="Particpant" source={props.source} property="ceterms:participant" />

			</PageSection>
			<style type="text/css">
				.relatedOrganizationLink {`{ margin: 0 0 0 40px; }`}
			</style>
		</>
	);
	
}

function OrganizationSectionAJAXResourceList(props: { label: string, source: any, property: string }) {
	return props.source[props.property] && (
		<PageSectionItem>
			<Label>{props.label}</Label>
			<AJAXResourceList
				urls={Array.isArray(props.source[props.property]) ? props.source[props.property] : [props.source[props.property]]}
				onSuccess={(data, itemContainer, itemList) => {
					itemContainer.innerHTML = "";
					renderLink(itemContainer, appURL + "/resources/" + data["ceterms:ctid"], getEnglish(data["ceterms:name"]), "_blank", "relatedOrganizationLink")
				}}
			/>
		</PageSectionItem>
	) || null
}
