import React from 'react';
import styled from 'styled-components/macro';
import { MainSearchType } from '../../types/internal';

import organizationIcon from '../../assets/images/icons/icon-organization-blue-green-01.svg';
import credentialIcon from '../../assets/images/icons/icon-credentials-blue-green-01.svg';
import assessmentIcon from '../../assets/images/icons/icon-assessments-blue-green-01.svg';
import learningOpportunityIcon from '../../assets/images/icons/icon-learning-opportunities-blue-green-01.svg';
import competencyFrameworkIcon from '../../assets/images/icons/icon-competency-framework-white-green-01.svg';
import pathwayIcon from '../../assets/images/icons/icon-pathways-blue-green-01.svg';
import pathwaySetIcon from '../../assets/images/icons/icon-pathwaysets-blue-green-01.svg';
import transerValueIcon from '../../assets/images/icons/icon-transfer-value-blue-green-01.svg';
import transerIntermediaryIcon from '../../assets/images/icons/icon-transfer-intermediary-blue-green-01.svg';
import qaActionIcon from '../../assets/images/icons/icon-quality-assurance-blue-green-01.svg';
import rubricIcon from '../../assets/images/icons/icon-quality-assurance-blue-green-01.svg';

const Wrapper = styled.div`
    text-align: center;
    padding: 40px;
`;

const Icon = styled.img`
    height: 40px;
`;

interface Props {
    type: string;
}

export default function EmptyResult(props: Props) {
    let icon = organizationIcon;
    switch (props.type) {
        case 'assessment':
            icon = assessmentIcon;
            break;
        case 'competencyframework':
            icon = competencyFrameworkIcon;
            break;
        case 'credential':
            icon = credentialIcon;
            break;
        case 'pathway':
            icon = pathwayIcon;
            break;
        case 'pathwayset':
            icon = pathwaySetIcon;
            break;
        case 'learningopportunity':
            icon = learningOpportunityIcon;
            break;
        case 'scheduledoffering':
            icon = transerValueIcon;
            break;
        case 'supportservice':
            icon = transerValueIcon;
            break;
        case 'transfervalue':
            icon = transerValueIcon;
            break;
        case 'transferintermediary':
            icon = transerIntermediaryIcon;
            break;
        case 'conceptscheme':
            icon = competencyFrameworkIcon;
            break;
		case 'progressionmodel':
		case 'occupation':
		case 'job':
		case 'task':
		case 'workrole':
            icon = competencyFrameworkIcon;
            break;
        case 'datasetprofile':
        case 'outcomedata':
            icon = competencyFrameworkIcon;
            break;
        case 'action':
        case 'credentialingaction':
        case 'accreditaction':
            icon = qaActionIcon;
            break;
        case 'rubric':
        case 'rubrics':
            icon = rubricIcon;
            break;
        default:
            break;
    }
    return (
        <Wrapper>
            <Icon src={icon} />
            <p>Sorry, no results were found, try another search</p>
        </Wrapper>
    );
}
