import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { TitleBlock, Wrapper, Title, HideButton } from './styles';
import CFilterItem from './CFilterItem';
import { isCheckboxFilter } from '../../utils';
import { MainSearchType } from '../../types/internal';
import { Filter, FilterItem } from '../../types/external';
import { widgetGetColor, widgetGetPluralLabelForText } from '../../utils/SiteWidgetizer';

// driven by categoryId and Id

interface Props {
    searchType: string;
    availableFilters: Filter[];
    currentFilters: Array<Filter>;
    onHide: () => void;
    onUpdateFilter: (filter: Filter, item: FilterItem) => void;
}

export default function FilterPanel(props: Props) {
    const ref = useRef<HTMLDivElement | null>(null);

	function getPanelTitle(searchType: string) {
		return "Filter " + widgetGetPluralLabelForText(({
			"credential": "Credentials",
			"assessment": "Assessments",
			"competencyFramework": "Competency Frameworks",
			"collection": "Collections",
			"pathway": "Pathways",
			"pathwayset": "Pathway Sets",
			"learningopportunity": "Learning Opportunities",
			"scheduledoffering": "Scheduled Offerings",
			"supportservice": "Support Services",
			"occupation": "Occupations",
			"job": "Jobs",
			"workrole": "Work Roles",
			"task": "Tasks",
			"transfervalue": "Transfer Values",
			"transferintermediary": "Transfer Intermediaries",
            "conceptscheme": "Concept Schemes",
            "progressionmodel": "Progression Models",
            "outcomedata": "Outcome Data",
            "datasetprofile": "Outcome Data",
            "organization": "Organizations",
            "credentialingaction":"Quality Assurance Actions",
            "rubric": "Rubrics"
		} as any)[searchType] || "Data");
    }

    function onHide() {
        props.onHide();
    }

    function getStatusOfFilterItem(
        filter: Filter,
        filterItem: FilterItem,
    ) {
        // logic to check if the checkbox is checked
		const isCheckbox = isCheckboxFilter(filterItem);
		var currentFilters = props.currentFilters || [];
		if (isCheckbox && currentFilters.find(m => m.Id == filter.Id)) {
			return (currentFilters || []).find(m => m.Id == filter.Id)?.Items?.some(
                (item) => {
					return item.InterfaceType == filterItem.InterfaceType && (item.Id ? item.Id == filterItem.Id : (item.URI == filterItem.URI));
                },
            ) || false;
        }
        return false;
    }

    function getAddedFilterItemsFromAutocomplete(filterIdentifier: string) {
        return (
			props.currentFilters.find(m => m.Id == filterIdentifier)?.Items.filter(
                (item) => item.Id !== undefined,
            ) || []
        );
    }

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    return (
        <Wrapper tabIndex={0} ref={ref}>
            <TitleBlock>
                <Title>{getPanelTitle(props.searchType)}</Title>
                <HideButton onClick={onHide}>
                    <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                    <span style={{ marginLeft: '10px' }}>Hide</span>
                </HideButton>
            </TitleBlock>
            {props.availableFilters.map((filter, index) => (
                <FilterType
                    key={`cr-main-filter-${filter.Id}`}
                    searchType={props.searchType}
                    filter={filter}
                    filterIdentifier={filter.Id}
                    autoCompleteFilterItems={getAddedFilterItemsFromAutocomplete(
                        filter.Id,
                    )}
                    getStatus={getStatusOfFilterItem}
                    onSelectFilter={props.onUpdateFilter}
                />
            ))}
        </Wrapper>
    );
}

interface FilterTypeProps {
    searchType: string;
    filter: Filter;
    filterIdentifier: string;
    autoCompleteFilterItems: FilterItem[];
    getStatus: (filter: Filter, filterItem: FilterItem) => boolean;
    onSelectFilter: (filter: Filter, item: FilterItem) => void;
}

const FilterTypeWrapper = styled.div``;
const FilterTypeTitle = styled.h4`
    cursor: pointer;
	color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.white)};
`;
const FilterButton = styled.button`
    display: flex;
	width: 100%;
    border: none;
    background: transparent;
    color: ${(p) => p.theme.color.white};
    align-items: center;
    padding: 0;

	&:hover, :focus {
		cursor: pointer;
		background-color: rgba(255,255,255,0.1);
	}
`;

function FilterType(props: FilterTypeProps) {
    const [expanded, setExpanded] = useState(false);

    return (
		<FilterTypeWrapper data-filterid={props.filter.Id} data-filteruri={props.filter.URI} data-filterlabel={props.filter.Label}>
            <FilterButton onClick={() => setExpanded(!expanded)}>
                <FontAwesomeIcon
                    icon={expanded ? faAngleDown : faAngleRight}
                    size="lg"
                    style={{ marginRight: '10px' }}
                />
                <FilterTypeTitle>{props.filter.Label}</FilterTypeTitle>
            </FilterButton>
            {expanded &&
                props.filter.Items.map((item, index) => (
                    <CFilterItem
                        key={`cr-filter-panel-${item.Id}-${index}`}
                        searchType={props.searchType}
                        filter={props.filter}
                        filterIdentifier={props.filterIdentifier}
                        item={item}
                        autoCompleteFilterItems={props.autoCompleteFilterItems}
                        status={props.getStatus(props.filter, item)}
                        onSelectFilter={props.onSelectFilter}
                    />
                ))}
        </FilterTypeWrapper>
    );
}
