import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import pageIcon from '../../assets/images/icons/icon-transfer-value-green-blue-01.svg';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
	Description,
	FullWidthWrapper,
	InnerWrapper,
	Label, Section,
	SectionLabel,
	Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { DataSetProfile } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import OccupationsAndIndustriesSection from "../../components/DetailPage/OccupationsAndIndustriesSection";
import DisplayPills from '../../components/PillDisplay';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";
import { ModalType } from "../../types/internal";
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { DataSetTimeFrameSingle } from '../../components/DetailPage/DataSetProfile';




interface Props {
	id: string;
}

export default function DataSetProfilePage(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [
		pageDataIsLoading,
		pageDataHasError,
		getPageData,
	] = useGet();
	const [pageModel, setPageData] = useState({} as DataSetProfile);
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
	});

	useEffect(() => {
		getPageData(`${apiUrl}/DataSetProfile/${id}`, (data: DataSetProfile) => {
			setPageData(data);
		});
	}, [getPageData, id]);
	//

	function getSubTitleLink() {
		if (pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
			return pageModel.OwnedByLabel.URL ?
				<WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
				<>{pageModel.OwnedByLabel.Label}</>;
		}
		return null;
	}

	const [openedModal, setOpenedModal] = useState<ModalType>();

	function onOpenModalClick(modalType: ModalType) {
		return () => setOpenedModal(modalType);
	}

	function onCloseModalClick() {
		setOpenedModal(undefined);
	}


	function getLegacyLink() {
		if (pageModel.Meta_Id) {
			const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
			const mystyle = {
				//color: "rgba(0,0,0,.01)",
				color: "transparent",
				//backgroundColor: "rgba(0,0,0,.01)",
				marginLeft: "10px"
			};
			return <>
				<WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
			</>

		}
		return null;
	}

	var typeLabel = widgetGetSingleLabelForText("Outcome Data");

	return (
		<LoadingOrError
			isLoading={pageDataIsLoading}
			hasError={pageDataHasError}>

			<Banner
				icon={pageIcon}
				fallBackIcon={pageIcon}
				type={pageModel.CTDLTypeLabel}
				title={pageModel.Name}
				subTitle={getSubTitleLink()}
				content={
					<small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
				}
			/>
			<Wrapper>
				<VStack spacing={isMobile ? '0px' : '20px'}>
					<HasAnyFilled
						data={pageModel}
						keys={detailSectionProperties.dataSetProfileAbout}>
						<PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>

							{pageModel.Description && (
								<PageSectionItem>
									<Description>
										{pageModel.Description}
									</Description>
								</PageSectionItem>
							)}
							{
								pageModel.About?.length > 0 &&
								<PageSectionItem>
									<Label>About</Label>
									<OutlineList items={pageModel.About} />
								</PageSectionItem>
							}
							{pageModel.Source &&
								<PageSectionItem>
									<LinkObject item={{ URL: pageModel.Source, Label: "View this " + typeLabel }} />
								</PageSectionItem>
							}
							{pageModel.DataSuppressionPolicy && (
								<PageSectionItem>
									<Label>Data Suppression Policy</Label>
									<div>{pageModel.DataSuppressionPolicy}</div>								
								</PageSectionItem>
							)}
							{pageModel.SubjectIdentification && (
								<PageSectionItem>
									<Label>Subject Identification</Label>
									<div>{pageModel.SubjectIdentification}</div>								
								</PageSectionItem>
							)}

							{pageModel.DistributionFile?.length > 0 &&
									pageModel.DistributionFile.map((url) => {
										<LinkObject item={{ URL: url, Label: "Distribution File" }} />
									})
								}

						</PageSection>
					</HasAnyFilled>
				</VStack>
				<VStack spacing={isMobile ? '0px' : '20px'}>
					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that offer the " + typeLabel} />
					

				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: "Time Frames",
							Content: (
								(
									pageModel.DataSetTimePeriod?.length > 0 &&
									<PageSection icon={connectionsIcon}
											title={"More Specific " + widgetGetPluralLabelForText("Outcome Data")}
											description={"Specific " + widgetGetPluralLabelForText("Outcome Data") + " that are part of this " + typeLabel}>
										{/*Label: "Coming Soon!"*/}
										<TabSetOrSingle
											activeIndex={0}
											items={pageModel.DataSetTimePeriod.map(
												(dataSetProfile, index) => (
													{
														Label: dataSetProfile.Name || ("Data Set " + (index + 1) ),
														Content: <DataSetTimeFrameSingle item={dataSetProfile} />
													}
												)
											)}
										/>
									</PageSection>
								) || null
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>										
										<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
									</VStack>
									<VStack>
										<HasAnyFilled
											data={pageModel}
											keys={[
												...detailSectionProperties.entityConnections
											]}>
											<PageSection
												icon={connectionsIcon}
												title="Connections"
												description="Related Resources"
											>
												<PageSectionItem>
													<ConnectionsSection
														item={pageModel}
														openedModal={openedModal}
														onOpenModalClick={onOpenModalClick}
														onCloseModalClick={onCloseModalClick}
													/>
												</PageSectionItem>
											</PageSection>
										</HasAnyFilled>
										
									</VStack>
								</InnerWrapper>
							)
						}
					]}
				/>
			</FullWidthWrapper>
		</LoadingOrError>
	);
}
//
